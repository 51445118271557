import {
  AdditionalOrganizationControllerApi,
  UpdateAdditionalOrganizationRequest
} from '@rallycry/api-suite-typescript/dist/apis/AdditionalOrganizationControllerApi'
import { CommunityControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CommunityControllerApi'
import { CommunityProfileControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CommunityProfileControllerApi'
import { CommunitySectionControllerApi } from '@rallycry/api-suite-typescript/dist/apis/CommunitySectionControllerApi'
import {
  CreateCommunityRequest,
  OrganizationControllerApi
} from '@rallycry/api-suite-typescript/dist/apis/OrganizationControllerApi'
import { AdditionalOrganizationCreateCommand } from '@rallycry/api-suite-typescript/dist/models/AdditionalOrganizationCreateCommand'
import { CommunityProfileCommand } from '@rallycry/api-suite-typescript/dist/models/CommunityProfileCommand'
import { CommunityProfileResource } from '@rallycry/api-suite-typescript/dist/models/CommunityProfileResource'
import { CommunitySectionCommand } from '@rallycry/api-suite-typescript/dist/models/CommunitySectionCommand'
import { CommunitySectionResource } from '@rallycry/api-suite-typescript/dist/models/CommunitySectionResource'
import { CommunitySettingsResource } from '@rallycry/api-suite-typescript/dist/models/CommunitySettingsResource'
import { CommunityUpdateCommand } from '@rallycry/api-suite-typescript/dist/models/CommunityUpdateCommand'
import { EmailMembersCommand } from '@rallycry/api-suite-typescript/dist/models/EmailMembersCommand'
import { some } from 'lodash-es'
import { useMemo } from 'react'
import { useSWRConfig } from 'swr'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { useOrganization } from '../organization/useOrganization'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useCommunityId } from './useCommunityId'
import { useCommunityMeta } from './useCommunityMeta'
import { expander } from '@/core/expand'
import { useController } from '@/core/hooks/useSWRApi'

export const useCommunity = (options?: EntityOptions) => {
  const communityId = useCommunityId(options)
  const { mutate } = useSWRConfig()
  const { organization } = useOrganization()
  const { ctrl: orgCtrl } = useController(OrganizationControllerApi)
  const { ctrl: profileCtrl } = useController(CommunityProfileControllerApi)
  const { ctrl: sectionCtrl } = useController(CommunitySectionControllerApi)
  const { ctrl: organizationCtrl } = useController(
    AdditionalOrganizationControllerApi
  )
  const { ctrl } = useController(CommunityControllerApi)

  // community entity is bound to two metas so that updates to community
  // or user can be cached separately
  const { read: meta, cache: metaCache } = useCommunityMeta(options)
  const { read: userMeta } = useUserMeta(options)

  const entity = useReadEntity({
    key: 'useCommunity',
    idOrKey: communityId,
    expand:
      '_links,profile{_links},section{_links},settings{_links},additionalOrganizations{organization},organization',
    ...options,
    metas: [userMeta, meta],
    read: (req, { root, communities, access }) =>
      ctrl({ metas: { root, communities, access } }).readCommunity(req),
    create: (req: CreateCommunityRequest) => orgCtrl().createCommunity(req),
    update: (id, cmd: CommunityUpdateCommand, expand) =>
      ctrl().updateCommunity({ id, communityUpdateCommand: cmd, expand })
  })

  const archive = async (id: number) => {
    await ctrl().archive({ id })
    await mutate(metaCache(id))
  }

  const updateProfile = async (id: number, cmd: CommunityProfileCommand) => {
    const req = { id, communityProfileCommand: cmd }
    await profileCtrl().updateCommunityProfile(req)
    await mutate(metaCache(id))
  }

  const updateSection = async (id: number, cmd: CommunitySectionCommand) => {
    const req = { id, communitySectionCommand: cmd }
    await sectionCtrl().updateCommunitySection(req)
    await mutate(metaCache(id))
  }

  const createAdditionalOrganization = async (
    cmd: AdditionalOrganizationCreateCommand
  ) => {
    await organizationCtrl().createAdditionalOrganization({
      communityId,
      additionalOrganizationCreateCommand: cmd
    })
    await mutate(metaCache(communityId))
  }

  const updateAdditionalOrganization = async (
    req: UpdateAdditionalOrganizationRequest
  ) => {
    await organizationCtrl().updateAdditionalOrganization(req)
    await mutate(metaCache(communityId))
  }

  const removeAdditionalOrganization = async (id: number) => {
    await organizationCtrl().removeAdditionalOrganization({
      id
    })
    await mutate(metaCache(communityId))
  }

  const emailMembers = async (cmd: EmailMembersCommand) => {
    await ctrl().emailCommunityMembers({
      id: communityId,
      emailMembersCommand: {
        ...cmd,
        members: some(cmd.members) ? cmd.members : undefined
      }
    })
  }

  const additionalOrgSettings = useMemo(
    () =>
      entity.read.data?._expanded?.additionalOrganization?.find(
        it => it.organization?.id === organization?.id
      ),
    [organization, entity.read.data]
  )

  return {
    ...entity,
    community: entity.read.data,
    additionalOrgSettings,
    profile: expander<CommunityProfileResource>(
      entity.read.data,
      'communityProfile'
    ),
    section: expander<CommunitySectionResource>(
      entity.read.data,
      'communitySection'
    ),
    settings: expander<CommunitySettingsResource>(
      entity.read.data,
      'communitySettings'
    ),
    isCommunityMember: !!entity.read.data?._links?.member,
    isCommunitySuperAdmin: !!entity.read.data?._links?.superAdministrate,
    isCommunityAdmin: !!entity.read.data?._links?.administrate,
    isCommunityModerator: !!entity.read.data?._links?.moderate,
    hasCommunityDelete: !!entity.read.data?._links?._delete,
    isTeam: entity.read.data?.kind === CommunityKind.TEAM,
    archive,
    updateProfile,
    updateSection,
    createAdditionalOrganization,
    updateAdditionalOrganization,
    removeAdditionalOrganization,
    emailMembers
  }
}
